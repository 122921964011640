.footer {
  background-color: #004684;
  color: white;
  /* height: 10vh; */
  text-align: center;
}

.footer .nav-item a {
  color: white;
  font-weight: normal;
}

.footer .nav {
  padding: 2em 0em;
}

#copyright {
  background-color: #004684;
  padding: 0em 0em 1em 0em;
  font-size: 7pt;
}

.footer-nav {
  display: flex !important;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
  font-size: 16px;
}

.innovfin-logo {
  max-width: 150px;
  padding: 0 0 1em 0;
  text-align: center;
  margin: auto;
}
