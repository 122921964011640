.section {
  overflow: hidden;
  margin-bottom: 5vh;
}

.popoutConent {
  background-color: #f7fafc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin-top: 3rem;
}
.missionImageStyle {
  width: 85%;
  height: auto;
  top: -70px;
  position: relative;
  margin-top: 2.5em;
}
.NFTs {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;

  max-width: 1000px;
  gap: 10px;
}

.setupSection {
}
.setupSectionContent {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}
.setupSectionContent .setupSectionDescription {
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.setupSection h2 {
  color: #014486;
  font-weight: bold;
  text-shadow: 1px 2px rgb(5 21 243 / 36%);
  margin-bottom: 1rem;
}


.blogPosts{
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  display: flex;
}
.blogPosts h2{
  margin-bottom: 48px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: rgb(4, 17, 29);
  line-height: 110%;
}
.blogPosts .blogPostsContainer{
  max-width: calc(3rem * 440px);
}

.blogItem{
  display: inline-block;
  border: 1px solid rgb(229, 232, 235);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  cursor: pointer;
  padding: 1.3%;
  width: 414px;
  visibility: visible;
    opacity: 1;
    transition: visibility 0s ease 0s, opacity 500ms ease 0s;
    margin: 0px 10px;
    width: 98%;
}
.blogItem .blogImageContainer{
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid rgb(229, 232, 235);
  background-color: rgb(229, 232, 235);
  height: 265px;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.blogImageContainer img{
  object-fit: cover;
}