*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;

}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #f7fafc;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article>*+* {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

body {
  font-family: "Lato", sans-serif;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #014486 !important;
}

a {
  text-decoration: none !important;
}

.ant-menu-horizontal>.ant-menu-item-selected a,
.ant-menu-horizontal>.ant-menu-item a:hover {
  color: #014486 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.market-section {
  margin-bottom: 10vh;
}

.terms {
  padding: 0em 20vw;
  margin-bottom: 75px;
}

.hero-section {
  margin-bottom: 2rem;
}

@media screen and (max-width: 481px) {
  .hero-section {
    flex-direction: column-reverse;
  }
}

.featuredWrapper {
  max-width: 355px;
  box-shadow: rgba(5, 21, 243, 0.364) 0px 0px 10px 0px;
  display: flex;
  max-width: 30vw;
  background-color: rgb(251, 253, 255);
}

.featuredContainer .featuredWrapper .featuredImageContainer {
  border-bottom: 1px solid rgb(229, 232, 235);
}

.featuredContainer .featuredWrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.featuredContainer .featuredWrapper a {
  border-radius: 10px;
}

.featuredContainer .featuredImageFooter {
  width: 100%;
  font-weight: 600;
  padding: 16px;
  display: flex;
  color: rgb(53, 56, 64);
  text-align: left;
  border-bottom: 1px solid rgb(229, 232, 235);
  margin-bottom: 10px;
}

.featuredContainer .featuredImageFooter .textContainer {
  align-self: stretch;
  flex: 1 1 auto;
  flex-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 16px;
  order: 3;
  overflow: hidden;
  font-size: 16px;
  align-items: flex-start;
  display: flex;
}

.featuredContainer .featuredImageFooter .textContainer p {
  font-weight: 600;
  font-size: 14px;
  color: rgb(4, 17, 29);
  margin-bottom: 0;
}

.featuredContainer .featuredImageFooter .infoButtonContainer {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 40%;
  order: 4;
  overflow: hidden;
  text-align: right;
}

.featuredContainer .featuredImageFooter .infoButton {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 100%;
  font-family: inherit;
  border: 0px;
  padding: 0px;
  background: inherit;
  appearance: button;
  cursor: pointer;
}

.hero-section-leading h1 {
  color: #014486;
  font-family: "Lato";
  font-weight: 900;
}

.hero-section-leading .text-blue {
  color: #014486;
}

.hero-section-leading p {
  font-family: "Lato";
  font-weight: 900;
  margin: 0;
  font-style: italic;
}

.hero-section-leading {
  margin-bottom: 5rem;
}

.hero-section-heading {
  opacity: 1;
  visibility: visible;
  transition: transform 1000ms ease opacity 1000ms ease;
  transition-delay: 1000ms;
  color: black;
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -2px;
  margin-bottom: 28px;
  font-weight: 700px;
}

.hero-section-subheading {
  line-height: 1.6;
  font-weight: 500;
  padding-right: 10%;

  color: #001d3b;
}

.hero-section-button-group {
  margin-top: 50px;
}

.hero-section-head {
  font-family: Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD,
    Georgia, serif;
  font-size: 3.125rem;
  color: #014486;
  font-weight: 900;
}

.hero-section-head2 {
  font-family: Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD,
    Georgia, serif;
  font-size: 3.125rem;
  color: #014486;
  font-weight: 900;
}

.heroBtn {
  display: inline-block;
  position: relative;
  border-radius: 5px !important;
  border: 1 solid #9ebde0 !important;
  outline: 0 none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  padding: 1em;
  background-color: #014486;
  margin-right: 12px;

  font-size: 16px;
}

.hero-section-info {
  position: relative;
  top: -79px;
  text-align: left;
}

.heroBtn:hover {
  color: #9ebde0;
}

.featuredImageCaption {
  color: #014486;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.joinBtn {
  display: inline-block;
  position: relative;
  border-radius: 5px !important;
  border: 1px solid #9ebde0 !important;
  outline: 0 none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  padding: 1em;
  background-color: #014486;
  margin: 2rem 0.5rem;
  justify-content: center;
  font-size: 16px;
}

.joinBtn:hover {
  color: #9ebde0;
}

#currentCollections a:not(.joinBtn) {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

#currentCollections a:not(.joinBtn):hover {
  box-shadow: rgba(5, 21, 243, 0.364) 0px 0px 10px 0px;
}



.italic {
  font-style: italic;
}

.missionStatementContainer {
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.missionStatementContainer .missionBox {
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  display: flex;
  text-align: center;
}

.missionBox h2 {
  color: #014486;
  font-weight: bold;
  text-shadow: 1px 2px rgba(5, 21, 243, 0.364);
}

.missionBox .missionItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 40px 0px 20px;
  width: 100%;
}

.missionBox .missionItems .mission {
  max-width: 20.25rem;
  padding: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.mission .icon_image_box {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0px;
}

.mission .icon_image {
  height: 10rem;
  width: auto;
}

.mission .title {
  color: #014486;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1.2rempx 0px;
}

.mission .missionContent {
  color: black;
  font-size: 1rem;
}

.missionLink {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.intro_video {
  filter: drop-shadow(15px -15px 0px #2d487c);
}

.featuredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-content {
  display: flex;
  justify-content: center;
  font-family: Roboto, sans-serif;
  color: #041836;
  margin-top: 130px;
  padding: 10px;
  
}

.home-NFTs {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
   justify-content: center; 

  /* max-width: 1100px; */
  gap: 10px;
}
.market-section h1{
  margin-bottom: 2rem !important;
}

.explore-banner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 600px;
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e3e3e3;
  flex-direction: column;
}

.combine-modal {
  height: 80vh;
}

.dropdown-toggle.btn.btn-primary{
  background-color: white;
  color: black;
  border: 1px solid darkgrey;
  width: 100%;
  text-align: left;
  border-radius: 0px;
}

.collections-options{
  max-width: 1000px;
  margin: 2em auto;
  /* justify-content: center; */
  text-align: center;
}

.collections-search{
  width: 80%;
  margin: auto 2em auto auto;
}

@media (max-width: 991px) {
  .featuredContainer {
    order: 2;
  }

  .featuredContainer-text {
    order: 1;
  }

  .explore-banner {
    width: auto;
  }
}

@media (max-width: 768px) {
  .home-content {
    margin-top: 2em;
  }

  .hero-section-head {
    margin-bottom: 0em;
    font-size: 32pt;
  }

  .hero-section-head2 {
    margin-bottom: 0em;
    font-size: 24pt;
  }

  .joinBtn {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .nav-link {
    text-align: center;
  }

  .navbar-toggler {
    order: 3;
  }

  #responsive-navbar-nav {
    order: 3;
  }
}

@media (max-width: 481px) {
  .featuredContainer {
    order: 1;
  }

  .featuredContainer-text {
    order: 2;
  }

  .home-NFTs {
    justify-content: center;
  }
}

.active {
  color: red;
}


.nftCard{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(229, 232, 235);
  border-radius: 10px;
  position: relative;
  
  width: 250px;
}


 .imageWrapper{

}

.nftCardWrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  color: black;
}

.imageWrapper img {
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px;

}
.nftCard .nftCardDescription{
  height: 126px;
  width: 100%;
  display: flex;

  justify-content: space-between;

}

.nft-list{
  display: flex;
  flex-wrap: wrap;
  --Webkit-box-start: start;
  margin-bottom: 3rem;

 
  justify-content: center;
  gap: 10px;
}
.nftCardDescriptionWrapper{
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.actionSection{
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 12px;
  height: 42px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(rgba(229, 232, 235, 0.392) 0%, rgb(255, 255, 255) 20%);
}

.actionList .buttonContainer{
  display: flex;
  font-weight: 500;
}

.buttonContainer button{
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 100%;
  font-family: inherit;
  border: 0px;
  padding: 0px;
  background: inherit;
  align-self: flex-end;
}

.nftCardDescription .small{
  color: rgb(112, 122, 131);
  font-weight: 500;
  font-size: 12px;
}

.nav-btn {
  background-color: #004684;
  border: none;
  margin-left: 1em;
}
.explore-banner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 600px;
  height: 150px;
  margin-bottom: 50px;
  padding-bottom: 60px;
  border-bottom: solid 1px #e3e3e3;
  flex-direction: column;
}

@media(max-width: 768px){
  .explore-banner{
    width: auto;
    height: auto;
  }
}