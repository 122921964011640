.pageContainer {
    padding-left: 0px;
    padding-right: 0px;
    width: 1280px;
    max-width: 100%;
    padding: 8px 8px 16px;
}

.pageContainer .pageWrapper {
    display: flex;
    flex-direction: row;
}

.pageContainer .nftSummary {
    flex: 3 0 0%;
    max-width: 43%;
    width: 0px;
}

.nftSummary .mediaContent{
    margin: 20px;
    border-radius: 10px;
    border: 1px solid black;
}
.mediaContent .mediaWrapper {
    cursor: pointer;
    max-height: 1000px;
    width: 100%;
    min-height: 200px;
    height: 100%;
    -webkit-box-align: center;
    align-items: center;
}

.mediaWrapper img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  
}

.extraContent{
    margin: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(229, 232, 235);
    overflow: hidden;
    flex: 3 0 0%;
    padding: 2rem;
   

}
.extraContent .title{
    font-size: 16px;
    font-weight: 600;
   
}
.extraContent .detailsContent {
    display: flex;
    flex-direction: column;
}

.detailsContent .detailsFlex-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageWrapper .nftMain {
    flex: 4 0 0%;
    margin-left: -20px; 
}

.nftMain .nftMainHeader{
    display: flex;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 20px 20px 15px;
}
.nftMainHeader .nftCollectionLead{
    font-size: 16px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.nftMainHeader .title{
    font-size: 30px;
    font-weight: 600;
    max-width: 100%;
    margin: 0px;
    width: 588px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
 .nftMain-item{
    margin: 20px;
    border-radius: 10px;
    padding: 20px;
    background-color: rgb(251, 253, 255);
    border: 1px solid rgb(229, 232, 235);
}

.nftMain-item-title{
    color: rgb(112, 122, 131);
}
.nftMain-item .priceSection {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    font-size: 30px;
    color: rgb(53, 56, 64);
    width: fit-content;
}
.nftMain-item .fiatWrapper{
    font-weight: normal;
    font-size: 15px;
    margin-left: 8px;
    margin-top: 15px;
    color: rgb(112, 122, 131);
}
.nftMain-item .buttonGroup {
    display: flex;
    
}

.nftMain-item  .buttonGroup button{


    border-radius: 10px;

    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;

    color: rgb(255, 255, 255); 
    
    display: inline-block;
    position: relative;
    border: 1px solid #1e4674;
    outline: 0 none;
    cursor: pointer;
    text-decoration: none;
    color: white;
  
    background-color: #014486;
    margin-right: 12px;
  
}


