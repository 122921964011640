#login-container {
    padding: 0em 7em;
    /* -ms-transform: translate(-50%, -50%); */
    transform: translate(0%, 25vh);
}

#login-container h1 + p{
    text-align: center;
}

#login-container h1{
    text-align: center;
}

#signup-container {
    padding: 0em 7em;
    /* -ms-transform: translate(-50%, -50%);
    transform: translate(0%, 25vh); */
}

#signup-container h1 + p{
    text-align: center;
}

#signup-container h1{
    text-align: center;
}

#reg-img {
    /* background: url("/loginBG.jpg") no-repeat; */
    background-size: cover;
    height: 100vh;
    /* color: white; */
    align-items: center;
}

#reg-img-signup {
    /* background: url("/loginBG.jpg") no-repeat; */
    background-size: cover;
    height: 100vh;
    /* height: auto; */
    /* color: white; */
    align-items: center;
}

#reg-img #register-container {
    transform: translate(0%, 35vh);
    align-items: center;
    justify-content: center;
    text-align: center;
}

#reg-img-signup #register-container {
    transform: translate(0%, 25vh);
    align-items: center;
    justify-content: center;
    text-align: center;
}

.account button {
    background-color: #004684;
    border: none;
}

.account-page {
    height: 100%;
    width: 100%;
    text-align: center;
}

.btn-reg {
    /* height: 3em; */
    width: 12em;
    padding: .75em 0em !important;
    font-size: 16pt !important;
    font-weight: bold !important;
    background-color: #004684 !important;
    border: none !important;
}

.btn-reg:hover {
    background-color: #0069d9 !important;
}

.btn-login {
    height: 3em;
    width: 100%;
    font-size: 16pt !important;
    font-weight: bold !important;
    margin: 1em 0em !important;
}

.form-check input {
    filter: drop-shadow(2px 1px 1px rgb(168, 168, 168));
}

.form-control {
    background-color: #F2F4F5 !important;
    border: none !important;
    height: 3.5em !important;
}

.account-form {
    margin: 1.5em 0em;
    background: rgba(116, 116, 116, 0.5)
}

.account-details {
    margin: 1.5em auto;
    padding: 2em 0em;
    background: rgba(116, 116, 116, 0.5);
    width: 50% !important;
}

.account-details h1 {
    margin: 0em 0em .5em 0em;
}

.account-details hr {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.col-sm-12{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media(max-width:1000px) {
    #login-container h1{
        font-size: 2em;
    }
    #login-container {
        padding: 0em 3em;
        transform: translate(0%, 0vh);
    }
    #signup-container h1{
        font-size: 2em;
    }
    #signup-container {
        padding: 0em 3em;
        transform: translate(0%, 0vh);
    } 
    #reg-img {
        height: auto;
    }
    #reg-img-signup {
        height: auto;
    }
}

@media(max-width:768px) {
    #reg-img {
        height: 100vh;
    }
    #reg-img-signup {
        height: 100vh;
    }
}